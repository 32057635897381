.theme-editor {
  margin-top: 30px;
  position: relative;

  .btn-close {
    position: absolute;
    right: 0;
    top: 0;
  }

  h1 {
    font-size: 15px;
  }

  label {
    margin-right: 10px;
  }
}
