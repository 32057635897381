$gallery-height: 60vh;

.layout-gallery {
  min-height: 100%;

  h1 {
    text-align: center;
    margin: 0 0 20px 0;
  }

  .image-gallery-image {
    height: $gallery-height;
    background-color: #CCC;
    position: relative;

    .asset-px {
      display: flex;
      height: 100%;
      flex-direction: column;
      justify-content: center;
    }

    img {
      display: block;
      margin: 0 auto;
      width: auto;
      max-width: 100%;
      max-height: 100%;
    }

    video {
      width: 100%;
    }
  }

  .image-gallery-description {
    background: rgba(#000, 0.7);
    max-height: 200px;
    overflow-y: auto;
    transform: translateY(50px);
    opacity: 0;
    transition: opacity ease-in-out .3s, transform ease-in-out .3s;

    .description {
      margin-bottom: 0;
    }
  }

  .description {
    margin-bottom: 15px;
  }

  .image-gallery-slide-wrapper:hover {
    .image-gallery-description {
      transform: translateY(0);
      opacity: 1;
    }
  }
}

.video-container {
  margin: 0 auto;
  position: relative;
  max-width: 100%;

  &, .video-js, video {
    max-height: $gallery-height;
  }

  .vjs-fullscreen {
    &, video {
      max-height: unset !important;
    }
  }

  img {
    cursor: pointer;
    max-width: 100%;
  }
}

.play-button {
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  height: 60px;
  width: 100px;
  background-color: rgba(0, 0, 0, .7);
  border-radius: 5px;

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 16.5px;
    left: 40px;
    margin: 0 auto;
    border-style: solid;
    border-width: 12.5px 0 12.5px 20px;
    border-color: transparent transparent transparent rgba(255, 255, 255, 1);
  }
}

.gallery-map {
  .map-container {
    position: relative;
    height: 150px;
  }
}
