@import url('https://fonts.googleapis.com/css?family=Comfortaa&display=swap');

.theme-light {
  background-image: linear-gradient(to left top, #9ab8e5, #98d0e6, #b3e2e1, #d9f1e3, #fbfff3);
  font-family: 'Comfortaa', cursive;

  #sidebar {
    background: #FFF;
  }
}
