.layout-grid {
  header {
    display: flex;
    justify-content: center;
    align-items: center;

    > * {
      margin: 10px;
    }
  }
}

.react-images__dialog {
  .desc {
    .download-btn {
      margin-top: 30px;
    }
  }

  .video-container {
    width: 100%;
  }
}

.flex-magnifier {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

#ReactGridGallery {
  margin-top: 15px;
}

.lb-asset-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  .desc {
    color: #FFF;

    @include media-breakpoint-up($mobile-thresold-up) {
      .description {
        max-height: calc(90vh - 200px);
        min-width: 300px;
        overflow: auto;
      }
    }
  }

  .asset {
    width: 600px;
  }

  @include media-breakpoint-up($mobile-thresold-up) {
    flex-direction: row;

    > div {
      margin-left: 15px;
      margin-right: 15px;
    }

    .asset {
      width: 600px;
      max-width: 75%;
    }

    .desc {
      max-width: 25%;
    }

    .flex-magnifier {
      //max-width: 50vw;
    }
  }

  @include media-breakpoint-down($mobile-thresold-down) {
    flex-direction: column;
    max-height: 100vh;

    > div {
      max-width: 100vw;
      margin-top: 15px;
      margin-bottom: 15px;
    }

    .asset {
      max-width: 100%;
    }

    .desc {
      padding: 15px;
      padding-bottom: 50px;
      overflow: auto;
    }

    .flex-magnifier {
      max-width: 100vw;
    }

    .react-images__view {
      height: auto;
    }
  }
}

.react-images__track {
  height: 100vh;
}

.react-images__view-wrapper {
  max-height: 100vh;
}
