@import "variables";
@import "~bootstrap/scss/bootstrap";
@import "~react-image-gallery/styles/scss/image-gallery.scss";
@import "Publication";
@import "layouts/index";
@import "themes/index";
@import "index/index";
@import "Scrollbar";
@import "Layout";
@import "Nav";
@import "video";
@import "error";
@import "FullPageLoader";

body, #root {
  min-height: 100vh;
  height: auto;
}

.exp-logo {
  img {
    max-width: 100%;
  }
}
