@import "~react-loader-spinner/dist/loader/css/react-spinner-loader.css";

html {
  min-height: 100%;
}

body, #root {
  height: 100%;
}

.full-page-loader {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
}
