.layout-mapbox {
  height: 100%;
  min-height: 100%;

  h1 {
    text-align: center;
    margin: 20px 0;
  }

  .description {
    margin-bottom: 25px;
  }

  .map-wrapper {
    position: relative;
  }

  .map-container {
    height: 50vh;

    .thumb {
      max-width: 200px;
    }
  }

  .map-controls {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    margin: 12px;
    background-color: #404040;
    color: #ffffff;
    z-index: 1 !important;
    padding: 6px;
    font-weight: bold;
  }

  .coordinates, .map-layout {
    display: inline-block;
  }

  .map-layout {
    margin-left: 15px;
  }

  .image-full {
    margin-top: 25px;

    img {
      max-width: 100%;
    }

    .description {
      margin-top: 15px;
    }
  }
}
