::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
::-webkit-scrollbar-thumb {
  background: $primary;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover{
  background: linear-gradient(13deg, $secondary 14%, $primary 64%);
}
::-webkit-scrollbar-track{
  background: #ffffff;
  border-radius: 10px;
  box-shadow: inset 7px 10px 12px #f0f0f0;
}
