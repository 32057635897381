.layout-download {
  h1 {
    text-align: center;
    margin: 20px 0;
  }

  .file-list {
    .media {
      border-bottom: 1px solid $primary;
      padding-bottom: 15px;
      margin-bottom: 15px;
    }

    .description {
      max-height: 100px;
      overflow: auto;
      margin-bottom: 15px;
    }

    img {
      max-width: 150px;
      max-height: 100px;
      margin-right: 15px;
    }
  }

}

.download-btns {
  text-align: right;

  .btn {
    margin-left: 10px;
  }
}
