
#sidebar {
  $width: 290px;
  min-width: $width;
  max-width: $width;
  background: $secondary;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
  position: relative;
  min-height: 100%;
  z-index: 1000;

  @include media-breakpoint-down($mobile-thresold-down) {
    position: absolute;
  }

  &.hidden {
    margin-left: -$width;

    .custom-menu {
      margin-right: -50px;
    }
  }

  .custom-menu {
    display: inline-block;
    position: absolute;
    top: 10px;
    right: 0;
    margin-right: -20px;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;

    .btn {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
  }
}

.authenticated-user {
  font-size: 13px;
  padding: 10px;

  .btn-logout {
    margin-top: 5px;
    background: $danger;
  }
}
