.cover {
  margin: 0 -1.5rem;
  img {
    width: 100%;
  }
}

.terms-invite {
  text-align: center;
  margin-top: 50px;
}

.main-content {
  flex-grow: 1;
  max-width: 100vw;
}
