
.filters {
  border-top: 1px solid $primary;
  border-bottom: 1px solid $primary;
  padding: 10px 0;
  margin-bottom: 30px;
  font-size: 13px;
  display: flex;
  justify-content: flex-end;
}

.publication-item {
  margin-bottom: 20px;

  transition: background-color .5s ease;

  &:hover {
    background-color: $secondary;
  }

  img {
    max-width: 150px;
    max-height: 100px;
  }

  a {
    &:hover {
      text-decoration: none;
    }

    .description {
      color: #000;
    }
  }
}
